<template>
    <div :key="key">
        <v-form lazy-validation ref="contactsListForm">
            <v-data-table
                    :headers="headers"
                    :items="Contacts"
                    calculate-widths
                    class="mt-3 appic-table-light specification-table"
                    dense
                    disable-pagination
                    disable-sort
                    hide-default-header
                    hide-default-footer
                    item-key="index"
            >
                <template v-slot:header="{ props: { headers } }">
                    <tr>
                        <th class="grey lighten-3 width-1-pct pa-1 text-left">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 width-1-pct pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 width-15-pct pa-1 text-left"><span class="ml-1">{{ headers[2].text }}</span></th>
                        <th class="grey lighten-3 pa-1 text-left width-10-pct"><span class="ml-1">{{ headers[3].text }}</span></th>
                        <th class="grey lighten-3 pa-1 text-left"><span class="ml-1">{{ headers[4].text }}</span></th>
                        <th class="grey lighten-3 pa-1 text-no-wrap text-left"><span class="ml-1">{{ headers[5].text }}</span></th>
                        <th class="grey lighten-3 width-1-pct pa-1 text-center">{{ headers[6].text }}</th>
    <!--                    <th class="grey lighten-3 width-1-pct pa-1 text-center">{{ headers[7].text }}</th>-->
    <!--                    <th class="grey lighten-3 width-1-pct pa-1 text-center">{{ headers[8].text }}</th>-->
                        <th class="grey lighten-3 width-1-pct pa-1 text-center" v-if="companyType == 'buyer'">{{ headers[7].text }}</th>
                        <th class="grey lighten-3 width-1-pct pa-1 text-center" v-else></th>
                        <th class="grey lighten-3 width-1-pct pa-1 text-center">{{ headers[8].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.lineNumber="{ item }">
                    <div class="text-center mb-0">{{ item.lineNumber }}</div>
                </template>
                <template v-slot:item.Contact.prefix="{ item }">
                    <v-select
                        :items="salutations"
                        :value="item.Contact.prefix"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.prefix',$event)"
                    ></v-select>
                </template>
                <template v-slot:item.Contact.name="{ item }">
                    <v-text-field
                        :placeholder="$t('message.name')"
                        :value="item.Contact.name"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.name',$event)"
                    ></v-text-field>
                </template>
                <template v-slot:item.Contact.department_id="{ item }">
                    <v-select
                        :items="companyDepartments"
                        :placeholder="$t('message.department')"
                        :value="item.Contact.company_department_id"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        item-text="CompanyDepartment.name"
                        item-value="CompanyDepartment.id"
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.company_department_id',$event)"
                    ></v-select>
                </template>
                <template v-slot:item.Contact.position="{ item }">
                    <v-text-field
                        :placeholder="$t('message.position')"
                        :value="item.Contact.position"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.position',$event)"
                    ></v-text-field>
                </template>
                <template v-slot:item.Contact.contact="{ item }">
                    <v-text-field
                        :placeholder="$t('message.mobileNo')"
                        :value="item.Contact.hp"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.hp',$event)"
                    >
                        <template v-slot:prepend><v-icon class="ti-mobile mt-1" small></v-icon></template>
                    </v-text-field>
                    <v-text-field
                        :placeholder="$t('message.directLine')"
                        :value="item.Contact.tel"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.tel',$event)"
                    >
                        <template v-slot:prepend><v-icon class="mt-1" small>phone</v-icon></template>
                    </v-text-field>
                    <v-text-field
                        :placeholder="$t('message.email')"
                        :rules="[rules.email]"
                        :value="item.Contact.email"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        hide-no-data
                        hide-selected
                        no-filter
                        @change="updateContactRowHandle( item.index, 'Contact.email',$event)"
                    >
                        <template v-slot:prepend><v-icon class="ti-email mt-1" small></v-icon></template>
                    </v-text-field>
                </template>
                <template v-slot:item.Contact.otsnotification="{ item }">
                    <v-checkbox
                        :true-value="1"
                        :input-value="item.Contact.otsnotification"
                        :label="$t('message.yes')"
                        class="custom pa-0 mt-0 ml-4"
                        dense
                        hide-details="auto"
                        small
                        solo
                        @change="updateContactRowHandle( item.index, 'Contact.otsnotification',$event)"
                    ></v-checkbox>
                </template>
                <template v-slot:item.Contact.otsprocurement="{ item }" v-if="false">
                    <v-checkbox
                        :true-value="1"
                        :input-value="item.Contact.otsprocurement"
                        class="custom pa-0 mt-0 ml-7"
                        dense
                        hide-details="auto"
                        small
                        solo
                        @change="updateContactRowHandle( item.index, 'Contact.otsprocurement',$event)"
                    ></v-checkbox>
                </template>
                <template v-slot:item.Contact.otsshipping="{ item }" v-if="false">
                    <v-checkbox
                        :true-value="1"
                        :input-value="item.Contact.otsshipping"
                        class="custom pa-0 mt-0 ml-3"
                        dense
                        hide-details="auto"
                        small
                        solo
                        @change="updateContactRowHandle( item.index, 'Contact.otsshipping',$event)"
                    ></v-checkbox>
                </template>
                <template v-slot:item.Contact.otspayments="{ item }" v-if="false">
                    <v-checkbox
                        :true-value="1"
                        :input-value="item.Contact.otspayments"
                        class="custom pa-0 mt-0 ml-3"
                        dense
                        hide-details="auto"
                        small
                        solo
                        @change="updateContactRowHandle( item.index, 'Contact.otspayments',$event)"
                    ></v-checkbox>
                </template>
                <template v-slot:item.Contact.appic_status="{ item }" v-if="companyType == 'buyer'">
                    <template v-if="item.Contact.id != null">
                        <div v-if="item.Contact.appic_status == null && item.Contact.existing_appic_access.length == 0 && $can('create','BuyerAppicAccess')">
<!--                            <v-btn small class="px-1" @click="openGrantAppicAccessDialog(item.Contact.name, item.Contact.email)">{{ $t('message.grantAccess') }}</v-btn>-->
                            <GrantAppicAccessV2
                                :contact-email="item.Contact.email"
                                :contact-name="item.Contact.name"
                                :customer-id="Customer__id"
                            ></GrantAppicAccessV2>
                        </div>
                        <div class="text-center d-flex flex-column" v-if="item.Contact.existing_appic_access.length > 0 ">
                            <div>{{ $t('message.existingAppicAccess') }}</div>
                            <div v-for="company in item.Contact.existing_appic_access">{{ company }}</div>
                        </div>
                        <div class="d-flex flex-column" v-if="item.Contact.appic_status != null && $can('update','BuyerAppicAccess')">
                            <v-switch
                                :false-value="0"
                                :true-value="1"
                                :input-value="item.Contact.appic_status"
                                class="pt-0 mt-0 font-sm ml-1"
                                hide-details="auto"
                                dense
                                @change="updateContactRowHandle( item.index, 'Contact.appic_status',$event)"
                            >
                                <template v-slot:label>
                                    <div class="font-sm">{{ item.Contact.appic_status == 1 ? $t('message.enabled') : $t('message.disabled') }}</div>
                                </template>
                            </v-switch>
                            <v-btn small class="mt-3 px-1" @click="openResetAppicPasswordDialog(item.Contact.name, item.Contact.email)" v-if="$can('update','BuyerAppicAccess')">{{ $t('message.resetPassword') }}</v-btn>
                            <v-btn small class="mt-3 px-1" @click="resendInvite(item.Contact.id, item.Contact.email)" v-if="$can('update','BuyerAppicAccess')">{{ $t('message.resendInvite') }}</v-btn>
                            <v-btn small class="my-3 px-1" @click="removeAccess(item.Contact.id, item.Contact.email)" v-if="$can('delete','BuyerAppicAccess')">{{ $t('message.removeAccess') }}</v-btn>
                        </div>
                    </template>
                    <template v-else>
                        <span class="red--text darken-1">{{ $t('message.pleaseSaveFirstGrantAccess') }}</span>
                    </template>
                </template>
                <template v-slot:item.index="{ item }">
                    <div class="ml-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="deleteItem(item.index, item.Contact.id)" v-on="on">
                                    <v-icon color="grey">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.deleteThisItem') }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-3 pt-2 px-0 d-flex justify-start">
                            <v-btn
                                @click="addContactRow(statePrefix)"
                                color="success"
                                small
                            >{{ Contacts.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                            </v-btn>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
<!--        <GrantAppicAccess-->
<!--            :contact-name="selectedContactName"-->
<!--            :contact-email="selectedContactEmail"-->
<!--            :customer-id="Customer__id"-->
<!--            :dialog.sync="grantAppicAccessDialog"-->
<!--            @access-granted="appicAccessGranted()"-->
<!--            @dialog-closed="grantAppicAccessDialogClosed()"-->
<!--        ></GrantAppicAccess>-->
        <AppicAccessResetPassword
            :contact-name="selectedContactName"
            :contact-email="selectedContactEmail"
            :dialog.sync="resetAppicPasswordDialog"
            @password-reset="appicPasswordReset()"
            @dialog-closed="resetAppicPasswordDialogClosed()"
        ></AppicAccessResetPassword>
    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";
    // import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapFieldsPrefixed,mapMultiRowFieldsPrefixed} from "../../helpers/vuex-map-fields-prefixed";
    import {api} from "Api";
    import GrantAppicAccessV2 from "Components/Appic/GrantAppicAccessV2";

    const GrantAppicAccess = () => import('Components/Appic/GrantAppicAccess');
    const AppicAccessResetPassword = () => import('Components/Appic/AppicAccessResetPassword');

    export default {
        name: "ContactList",
        components: {GrantAppicAccessV2, AppicAccessResetPassword, GrantAppicAccess},
        props: ['companyType','updateMode','validate'],
        data(){
            return {
                user: null,
                createDialog: false,
                grantAppicAccessDialog: false,
                key: 0,
                resetAppicPasswordDialog: false,
                rules: {
                    email: v => !!v || this.$t('message.required'),
                },
                salutations: [
                    {value: "Mr", text: "Mr"},
                    {value: "Mrs", text: "Mrs"},
                    {value: "Ms", text: "Ms"},
                    {value: "Na", text: "Na"},
                ],
                selectedContactEmail: null,
                selectedContactName: null,
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('buyer',{
                Customer__company_id: 'Customer.company_id',
                Customer__id: 'Customer.id'
            }, 'statePrefix'),
            ...mapFieldsPrefixed('supplier',{
                Supplier__company_id: 'Supplier.company_id'
            }, 'statePrefix'),
            ...mapMultiRowFieldsPrefixed('contact',{
                Contacts: 'Contacts',
            },'statePrefix'),
            ...mapGetters([
                'companyDepartments'
            ]),
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        id: 1,
                        text: this.$t('message.salutation'),
                        value: 'Contact.prefix'
                    },
                    {
                        id: 2,
                        text: this.$t('message.name'),
                        value: 'Contact.name'
                    },
                    {
                        id: 3,
                        text: this.$t('message.department'),
                        value: 'Contact.department_id'
                    },
                    {
                        id: 4,
                        text: this.$t('message.position'),
                        value: 'Contact.position'
                    },
                    {
                        id: 5,
                        icon: 'ti-mobile',
                        text: this.$t('message.contact'),
                        value: 'Contact.contact'
                    },
                    {
                        id: 6,
                        text: this.$t('message.autoEmailNotifications'),
                        value: 'Contact.otsnotification'
                    },
                    // {
                    //     id: 6,
                    //     text: this.$t('message.proc'),
                    //     value: 'Contact.otsprocurement'
                    // },
                    // {
                    //     id: 7,
                    //     text: this.$t('message.shpg'),
                    //     value: 'Contact.otsshipping'
                    // },
                    // {
                    //     id: 8,
                    //     text: this.$t('message.acct'),
                    //     value: 'Contact.otspayments'
                    // },
                    {
                        id: 7,
                        text: this.$t('message.appicAccess'),
                        value: 'Contact.appic_status'
                    },
                    {
                        id: 8,
                        text: this.$t('message.actions'),
                        value: 'index'
                    }
                ]
                return headers
            }
        },
        methods: {
            ...mapActions('contact',{
                addContactRow: 'addContactRow',
                deleteContact: 'deleteContact',
                deleteContactRow: 'deleteContactRow',
                resetAllContacts: 'resetAllContacts'
            }),
            appicAccessGranted() {
                this.grantAppicAccessDialog = false;
                this.$emit('reload-contact-list')
            },
            appicPasswordReset() {
                this.grantAppicAccessDialog = false
            },
            async deleteItem(itemIndex,itemId) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteContactAction'), {color: 'red'})) {
                    let _this = this
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deleteContactRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=> {
                        if (itemId != null) {
                            this.deleteContact({
                                company_id: this.companyType == 'buyer' ? this.Customer__company_id : this.Supplier__company_id,
                                contact_id: itemId
                            })
                        }
                    });
                }
            },
            grantAppicAccessDialogClosed() {
                this.grantAppicAccessDialog = false
            },
            openGrantAppicAccessDialog(contactName, contactEmail) {
                if(contactEmail != null){
                    this.selectedContactName = contactName
                    this.selectedContactEmail = contactEmail
                    this.grantAppicAccessDialog = true
                } else {
                    this.$toast.success(this.$t('message.errors.cannotGrantAppicAccessNoEmail'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                }
            },
            openResetAppicPasswordDialog(contactName, contactEmail) {
                this.selectedContactName = contactName
                this.selectedContactEmail = contactEmail
                this.resetAppicPasswordDialog = true
            },
            async removeAccess(contactId,contactEmail) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteContactAction'), {color: 'red'})) {
                    let promise = new Promise ((resolve, reject) => {
                        api
                            .delete("/contacts/" + contactEmail + "/appic-access")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve('done')
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    })

                    promise
                            .then(response => {
                                if(response == 'done'){
                                    this.$toast.success(this.$t('message.successes.appicAccessGranted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.$emit('reload-contact-list')
                                } else {
                                    this.$toast.error(response,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.$emit('reload-contact-list')
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.appicAccessNotGranted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.$emit('reload-contact-list')
                            })
                }
            },
            async resendInvite(contactId,contactEmail) {
                if (await this.$root.$confirm(this.$t('message.resendInvite'), this.$t('message.confirmations.continueResendInviteAction'), {color: 'red'})) {
                    let promise = new Promise ((resolve, reject) => {
                        api
                            .put("/contacts/" + contactId + "/resend-appic-access")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve('done')
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(error => {
                                reject(error);
                            });
                    })
                    promise
                        .then(response => {
                            if(response == 'done'){
                                this.$toast.success(this.$t('message.successes.appicAccessResent'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.$emit('reload-contact-list')
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.$emit('reload-contact-list')
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.appicAccessNotResent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.$emit('reload-contact-list')
                        })
                }
            },
            resetAppicPasswordDialogClosed() {
                this.resetAppicPasswordDialog = false
            },
            updateContactRowHandle (idx,field,value) {
                switch(field){
                    case 'Contact.appic_status':
                        let contactEmail = this.Contacts[idx]['Contact']['email']
                        let status = {
                            appic_status: value
                        }
                        let promise = new Promise((resolve, reject) => {
                            api
                                .put("/contacts/" + contactEmail + "/appic-access",{
                                    status: status
                                })
                                .then(response => {
                                    if (response.data.status == 'success') {
                                        resolve('done')
                                    } else {
                                        resolve(response.data.message)
                                    }
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        })
                        if(value == 0) {
                            if (this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueSuspendAppicAccessAction'), {color: 'red'})) {
                                promise
                                    .then(response => {
                                        if(response == 'done'){
                                            this.$toast.success(this.$t('message.successes.appicAccessSuspended'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'check_circle_outline'
                                                }
                                            )
                                            this.$emit('reload-contact-list')
                                        } else {
                                            this.$toast.error(response,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                            this.$emit('reload-contact-list')
                                        }
                                        var payload = {
                                            index: idx,
                                            field: field,
                                            prefix: this.statePrefix,
                                            value: value,
                                        }
                                        this.$store.commit('contact/updateContacts', payload)
                                    })
                                    .catch(error => {
                                        this.$toast.error(this.$t('message.successes.appicAccessNotSuspended'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.$emit('reload-contact-list')
                                    })
                            }
                        } else {
                            promise
                                .then(response => {
                                    if(response == 'done'){
                                        this.$toast.success(this.$t('message.successes.appicAccessEnabled'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        this.$emit('reload-contact-list')
                                    } else {
                                        this.$toast.error(response,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.$emit('reload-contact-list')
                                    }
                                    var payload = {
                                        index: idx,
                                        field: field,
                                        prefix: this.statePrefix,
                                        value: value,
                                    }
                                    this.$store.commit('contact/updateContacts', payload)
                                })
                                .catch(error => {
                                    this.$toast.error(this.$t('message.errors.appicAccessNotEnabled'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.$emit('reload-contact-list')
                                })
                        }
                        this.$emit('reload-contact-list')
                        break
                    default:
                        if(value === undefined) value = null
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        this.$store.commit('contact/updateContacts', payload)
                }
            }
        },
        watch: {
            validate(value) {
                if(value){
                    let valid = this.$refs.contactsListForm.validate()
                    if(valid) {
                        this.$emit('items-valid')
                    } else {
                        this.$emit('items-invalid')
                        this.$toast.error(this.$t('message.errors.requiredFieldsEmpty'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                }
            }
        },
        created() {
            // this.resetAllContacts('current')
            // this.$store.dispatch('resetConstantsState')
        },
        mounted() {
            this.$store.watch(
                function(state) {
                    return state.appic.contact.update.Contacts
                },
                () => {
                    this.updatedBuyerUnsaved = true
                },
                { deep: true }
            )

            this.$store.watch(
                function(state) {
                    return state.appic.contact.current.Contacts
                },
                () => {
                    this.newBuyerUnsaved = true
                },
                { deep: true }
            )
        }
    }
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-data-table td {
        padding: 0 5px !important;
    }
    .v-input__append-inner {
        margin-left: 0px !important;
    }
</style>
